<template>

<app-content :loading="is.loading && !is.initialised">

	<app-content-head :title="parent.name" :image="parent.image" :tabs="tabs" back="Convention.Schedule.Lobbies">

	</app-content-head>

	<app-events-filter :filter="filter" :references="references" />

	<app-events v-on:hide="load" :loading="is.loading && is.initialised" :filter="filter" :collection="collection" :columns="columns" :pagination="pagination" :total="total" placeholder="Sorry, no events found." />

</app-content>

</template>

<script>

import mixCollection from '@/mixin/collection'

export default {

	mixins: [mixCollection],

	data: function() {

		return {
			gatekeep: 'convention/schedule/lobby',
			endpoint: 'convention/schedule/events/:lobby',
			live: 'convention/schedule/event',
			filterTemplate: 'events',
			references: {
				times: [],
				days: [],
				lobbies: []
			},
			columns: {
				game: 200,
				seats: 100,
				date: 200,
				experience: 100,
				duration: 100,
				location: 'auto',
				actions: 72
			}
		}

	},

	computed: {

		tabs: function() {

			return [
				{ name: 'Convention.Schedule.Lobby', params: { id: this.parent.id }, text: 'Overview' },
				{ name: 'Convention.Schedule.Lobby.Events', text: 'Events' }
			]

		}

	}

}

</script>

<style scoped>

</style>